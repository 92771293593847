import './randomcase.css'
import copyToClipboard from '../../assets/js/copy.js'
import React from 'react';
import $ from 'jquery';

export default function Randomcase() {

React.useEffect(() => {
    var input = $("#input");
    var output = $("#output")
    var ditconmemay = input.val();
    function randomCase() {
        ditconmemay = input.val().split('').map((v) =>
        Math.round(Math.random()) ? v.toUpperCase() : v.toLowerCase()
        ).join('');
    };

    document.getElementById("input").addEventListener("input", function() {
        randomCase(ditconmemay);
        output.html(ditconmemay);
    });
})

    return (
        <div className="wrapper">
        <h1>Random Case</h1>
        <div className="input-wrapper">
            <h4>Input</h4>
            <textarea type="text" name="ogcase" id="input" placeholder="whatever" autoFocus></textarea>
        </div>
        <span className="icon material-symbols-rounded">arrow_drop_down</span>
        <div id="output" onClick={() => {copyToClipboard('#output'); alert('Đã copy')}}></div>
    </div>
    )
}