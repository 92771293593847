import './App.css';
import './color_pallete/css/theme.css'
import './assets/css/font.css'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/home/home'
import Navbar from './components/navbar/navbar.js'
import Randomcase from './pages/randomcase/randomcase';
import Emojipasta from './pages/emojipasta/emojipasta'
import Cringetitle from './pages/cringetitle/cringetitle';

function App() {
  return (
    <div className="appWrapper">
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/emojipasta" element={<Emojipasta />} />
          <Route path="/randomcase" element={<Randomcase />} />
          <Route path="/cringetitle" element={<Cringetitle />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
