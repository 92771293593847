import './home.css'
import L from '../../../package.json'

export default function Home() {
    return(
        <div className="home-wrapper">
        <h1>DitmeNavi 3 demo</h1>
        <p>Nothing to see here (for real there isn't any)</p>
        <p>Version: {L.version}</p>
        <img alt="Logo" src="assets/images/logo.png" />
      </div>
    )
}