import './navbar.css'
import Fab from '../fab/fab.js'
import { NavLink } from 'react-router-dom'

export default function Navbar() {
    return (
        <div className ="navbar">
            <Fab onClick={() => {console.log("clicked")}}>
                <span className="md-icon material-symbols-rounded">add</span>
            </Fab>
            <div className="divider"></div>
            <div className="navbar-items">
                <NavLink to="/">
                    <div className="navbar-item">
                        <span className="material-symbols-rounded">home</span>
                    </div>
                </NavLink>
                <NavLink to="/emojipasta">
                    <div className="navbar-item">
                        <span className="material-symbols-rounded">add_reaction</span>
                    </div>
                </NavLink>
                <NavLink to="/randomcase">
                    <div className="navbar-item">
                        <span className="material-symbols-rounded">format_size</span>
                    </div>
                </NavLink>
                <NavLink to="/cringetitle">
                    <div className="navbar-item">
                        <span className="material-symbols-rounded">live_help</span>
                    </div>
                </NavLink>
            </div>
        </div>
    )
}