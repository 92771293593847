import './emojipasta.css'
import EMOJI_MAPPINGS from './emoji-mappings.js'
import copyToClipboard from '../../assets/js/copy.js'
import React from 'react';

var MAX_EMOJIS_PER_BLOCK = 2;

function generateEmojipasta(text) {
    var blocks = splitIntoBlocks(text);
    var newBlocks = [];
    blocks.forEach(block => {
        newBlocks.push(block);
        var emojis = generateEmojisFrom(block);
        if (emojis) {
            newBlocks.push(" " + emojis);
        }
    });
    return newBlocks.join("");
}

function splitIntoBlocks(text) {
    return text.match(/\s*[^\s]*/g);
}

function generateEmojisFrom(block) {
    var trimmedBlock = trimNonAlphanumericalChars(block);
    var matchingEmojis = getMatchingEmojis(trimmedBlock);
    var emojis = [];
    if (matchingEmojis) {
        var numEmojis = Math.floor(Math.random() * (MAX_EMOJIS_PER_BLOCK + 1));
        for (var i = 0; i < numEmojis; i++) {
            emojis.push(matchingEmojis[Math.floor(Math.random() * matchingEmojis.length)]);
        }
    }
    return emojis.join("");
}

function trimNonAlphanumericalChars(text) {
    return text.replace(/^\W*/, "").replace(/\W*$/, "");
}

function getMatchingEmojis(word) {
    var key = getAlphanumericPrefix(word.toLowerCase());
    if (key in EMOJI_MAPPINGS) {
        return EMOJI_MAPPINGS[key];
    }
    return [];
}

function getAlphanumericPrefix(s) {
    return s.match(/^[a-z0-9]*/i);
}

export default function Emojipasta() {
    React.useEffect(() => {
        document.getElementById("input").addEventListener("input", function() {
            var text = document.getElementById("input").value;
            document.getElementById("output").innerHTML = generateEmojipasta(text);
        });
    })
    return (
        <div className="wrapper">
        <h1>EmojiPasta</h1>
        <div className="input-wrapper">
            <h4>Input</h4>
            <textarea type="text" id="input" placeholder="whatever" autoFocus></textarea>
        </div>
        <span className="material-symbols-rounded icon">arrow_drop_down</span>
        <div id="output" onClick={() => {copyToClipboard('#output'); alert('Đã copy')}}></div>
    </div>
    )
}